<template>
  <v-data-table
    must-sort
    fixed-header
    :loading="loading"
    :headers="tableHeaders"
    :items="users"
    item-key="uuid"
    :options.sync="options"
    :server-items-length="totalItems"
    :footer-props="{
      itemsPerPageOptions: [ 10, 25, 50, 100 ],
      itemsPerPageText: `${ $options.filters.capitalize($tc('user', 2)) } ${ $t('per page') }:`,
      showFirstLastPage: true
    }"
    class="elevation-2"
  >
    <template #top>
      <v-row
        justify="space-between"
        class="pt-3 px-3 mb-n3 "
      >
        <v-col md="3">
          <search-bar :value.sync="options.search" />
        </v-col>
        <v-spacer />
        <v-col
          v-if="$auth.check({ clients: ['edit', 'view'] })"
          md="2"
        >
          <client-filter
            key="client-filter"
            :value.sync="options.filter.client"
            :params="{ has: 'users' }"
          />
        </v-col>
        <v-col class="shrink">
          <v-btn
            v-if="$auth.check({users: ['add','edit']})"
            color="success"
            fab
            small
            class="mt-n5"
            @click.stop="$emit('toggle-right-drawer', {}, true)"
          >
            <v-icon small>
              fal fa-plus
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </template>
    <template #item.avatar="{ item }">
      <v-avatar
        size="36"
        class="my-3"
      >
        <avatar
          :username="item.first_name + ' ' + item.last_name"
          :src="item.avatar"
          :size="36"
        />
      </v-avatar>
    </template>
    <template #item.client="{ item }">
      <client-icon
        :key="item.client.uuid"
        :name="item.client.name"
        :icon="item.client.icon"
      />
    </template>
    <template #item.last_login="{ item }">
      {{ item.last_login | moment('L LT') }}
    </template>
    <template #item.active="{ item }">
      <v-icon v-text="item.active ? 'mdi-check' : ''" />
    </template>
    <template #item.actions="{ item }">
      <v-menu
        v-if="$auth.check({users: 'edit', admin: 'impersonate'})"
        bottom
        right
        offset-y
      >
        <template #activator="{ on }">
          <v-btn
            small
            ripple
            icon
            plain
            v-on="on"
          >
            <v-icon>fal fa-ellipsis-v</v-icon>
          </v-btn>
        </template>
        <v-list
          class="py-0"
          dense
        >
          <v-list-item
            @click="$emit('toggle-right-drawer', item, true)"
          >
            <v-list-item-action class="mr-4">
              <v-icon small>
                fal fa-user-edit fa-fw
              </v-icon>
            </v-list-item-action>
            <v-list-item-title>
              Edit User
            </v-list-item-title>
          </v-list-item>
          <impersonate
            v-if="$auth.check({admin: 'impersonate'}) && $auth.user().uuid != item.uuid && item.client.uuid != '8e3129d2-422b-4db5-b5fb-94acd2555286'"
            :record="item"
          />
        </v-list>
      </v-menu>
    </template>
  </v-data-table>
</template>
<script>
  export default {
    components: {
      SearchBar: () => import('@/components/tables/filters/SearchBar.vue'),
      ClientFilter: () => import('@/components/tables/filters/ClientFilter.vue'),
      ClientIcon: () => import('@/components/ClientIcon.vue'),
      Impersonate: () => import('@/components/tables/dialogs/Impersonate.vue'),
      Avatar: () => import('vue-avatar'),
    },
    props: {
      search: {
        type: String,
        default: null,
      },
    },
    metaInfo () {
      return {
        title: this.$options.filters.capitalize(this.$tc('user', 2)),
      }
    },
    data () {
      return {
        right: false,
        loading: false,
        options: {
          filter: this.$route.query,
          search: null,
          sortBy: ['last_name'],
          sortDesc: [false],
        },
        totalItems: null,
        users: [],
      }
    },
    computed: {
      tableHeaders () {
        var headers = [
          {
            value: 'avatar',
          },
          {
            text: this.$t('first name'),
            value: 'first_name',
          },
          {
            text: this.$t('last name'),
            value: 'last_name',
          },
          {
            text: this.$t('email'),
            value: 'email',
          },
          {
            text: this.$options.filters.capitalize(this.$t('phone')),
            value: 'phone',
          },
          {
            sortable: false,
            text: this.$t('client'),
            value: 'client',
          },
          {
            text: this.$t('Last Login'),
            value: 'last_login',
          },
        //   {
        //     text: 'Active',
        //     value: 'active',
        //   },
          {
            sortable: false,
            align: 'right',
            value: 'actions',
          },
        ]
        if (!this.$auth.check({ clients: ['view','edit','*'] })) {
          headers = headers.filter(x => x.value && x.value != 'client')
        }
        return headers
      },
    },
    watch: {
      options: {
        deep: true,
        handler () {
          this.fetchUsers()
        },
      },
    },
    mounted() {
      this.$root.$on('fetch-users', data => {
        this.fetchUsers(data)
      })
    },
    methods: {
      fetchUsers () {
        this.loading = true
        const promise = this.axios.get('admin/users', {
          params: {
            ...{
              page: this.options.page,
              count: this.options.itemsPerPage,
              sort: this.options.sortBy[0],
              order: this.options.sortDesc[0] ? 'desc' : 'asc',
              search: this.options.search,
            },
            ...this.$route.query,
          },
        })

        return promise.then((response) => {
          if (response.data.last_page < response.data.current_page) {
            this.options.page = response.data.last_page
            this.fetchUsers()
            return
          }
          this.totalItems = response.data.total
          this.options.page = response.data.current_page
          this.options.itemsPerPage = Number(response.data.per_page)
          this.users = response.data.data
        }).catch(error => {
          if (error.response?.data?.message) {
            this.$toast.error(error.response.data.message)
          }
        }).finally(() => {
          this.loading = false
        })
      },
      resetPassword (user) {
        this.$dialog.confirm({
          text: 'Send password reset email to user?',
          title: 'Reset Password',
        })
          .then((res) => {
            if (res) {
              this.axios.post('auth/password', { email: user.email })
                .then(() => {
                  this.$toast.success('Password reset info sent')
                })
                .catch(error => {
                  if (error.response?.data?.message) {
                    this.$toast.error(error.response.data.message)
                  }
                })
            }
          })
      },
      deleteUser (user) {
        this.$dialog.warning({
          text: 'Do you really want to delete this user?',
          title: 'Delete User',
          icon: 'fas fa-exclamation-triangle mr-2',
          actions: {
            true: {
              text: 'Yes', color: 'red',
            },
            false: 'No',
          },
        })
          .then((res) => {
            if (res) {
              this.axios.delete('admin/users/' + user.uuid)
                .then(() => {
                  const index = this.users.findIndex(x => x.uuid === user.uuid)
                  this.$delete(this.users, index)
                  this.$toast.success('User deleted')
                })
                .catch(error => {
                  this.$toast.error(error)
                })
            }
          })
      },
    },
  }
</script>
